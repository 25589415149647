import { ChevronRightIcon, Icon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Checkbox,
  Container,
  HStack,
  Image,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { FaOpencart, FaTrashAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  addToCart,
  decreaseCart,
  getTotal,
  removeCartItem,
} from '../../Redux/slice/cartSlice';

function Cart() {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  // const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
  //   useNumberInput({
  //     step: 1,
  //     defaultValue: 0,
  //     min: 0,
  //   });
  // const inc = getIncrementButtonProps();
  // const dec = getDecrementButtonProps();
  // const input = getInputProps();

  useEffect(() => {
    dispatch(getTotal());
  }, [cart, dispatch]);

  console.log('cart:', cart);

  // const handleInputQuantity = (e) => {
  //   console.log(e);
  // };

  const handleIncreaseCart = (cartItem) => {
    dispatch(addToCart(cartItem));
  };

  const handleDecreaseCart = (cartItem) => {
    dispatch(decreaseCart(cartItem));
  };

  const handleRemoveCartItem = (cartItem) => {
    dispatch(removeCartItem(cartItem));
  };
  return (
    <Box bg='#f0f0f0'>
      <Container maxW='1200px' margin='0 auto' padding='15px 10px'>
        <Breadcrumb separator={<ChevronRightIcon color='gray.500' />}>
          <BreadcrumbItem>
            <Link to={'/'}>
              <BreadcrumbLink>Home</BreadcrumbLink>
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Giỏ hàng</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Box
          w={'820px'}
          m='0 auto'
          p='20px'
          boxShadow={'0px 4px 4px rgb(0 0 0 / 25%)'}
          bg='white'
          //   borderRadius='15px'
        >
          <Text fontSize={'20px'}>Giỏ hàng</Text>
          {cart.cartList.length === 0 ? (
            <Box textAlign={'center'}>
              <Icon as={FaOpencart} fontSize='50px' />
              <Text my={'25px'} fontSize={'18px'} color='rgba(0, 0, 0, 0.71)'>
                Không có sản phẩm nào trong giỏ hàng của bạn
              </Text>
              <Link to='/'>
                <Button
                  bg={'#48a147'}
                  color='white'
                  _hover={{ bg: '#206f1f', boxShadow: '1px 1px 5px #c7c7c7' }}>
                  MUA HÀNG NGAY
                </Button>
              </Link>
            </Box>
          ) : (
            <TableContainer>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th p={'0'} textAlign={'center'}>
                      Chọn
                    </Th>
                    <Th
                    // w={'200px'}
                    >
                      Tên sản phẩm
                    </Th>
                    <Th textAlign={'center'}>Ảnh</Th>
                    <Th
                      // w='180px'
                      textAlign={'center'}>
                      Số lượng
                    </Th>
                    <Th
                      // w={'180px'}
                      p='0'
                      textAlign={'center'}>
                      Giá
                    </Th>
                    <Th>Xóa</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {cart.cartList.map((item, index) => (
                    <Tr key={index} p={'0 5px'}>
                      <Td p={'0 5px'} textAlign='center'>
                        <Checkbox defaultChecked />
                      </Td>
                      <Td
                        // maxW={'200px'}
                        whiteSpace={'normal'}>
                        <Text fontSize={'16px'} fontWeight={'bold'}>
                          {item.name}
                        </Text>
                      </Td>
                      <Td
                        // w={'180px'}
                        px='0'
                        textAlign={'center'}>
                        <Image
                          boxSize='80px'
                          objectFit={'cover'}
                          src={item.image?.slice(0, 1)}
                        />
                      </Td>
                      <Td
                        // w='180px'
                        textAlign={'center'}>
                        <HStack>
                          <Button
                            // {...dec}
                            onClick={() => handleDecreaseCart(item)}
                            size='sm'>
                            -
                          </Button>
                          <Input
                            // onChange={(e) => handleInputQuantity()}
                            value={item.quantity}
                            width='50px'
                            size='sm'
                            textAlign={'center'}
                          />
                          <Button
                            //  {...inc}
                            onClick={() => handleIncreaseCart(item)}
                            size='sm'>
                            +
                          </Button>
                        </HStack>
                      </Td>
                      <Td
                        // w={'180px'}
                        p='0'
                        textAlign={'center'}>
                        <Text fontWeight='500'>
                          {item.price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                          đ
                        </Text>
                      </Td>
                      <Td>
                        <Icon
                          as={FaTrashAlt}
                          fontSize='20px'
                          onClick={() => handleRemoveCartItem(item)}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th>
                      <Checkbox defaultChecked />
                    </Th>
                    <Th>Chọn tất cả</Th>
                    <Th textAlign={'center'}>Xóa</Th>
                    <Th textAlign={'center'}>Tổng tiền:</Th>
                    <Th
                      w={'20%'}
                      p='0'
                      fontSize={'16px'}
                      textAlign='center'
                      textTransform={'none'}
                      color={'var(--primary)'}
                      fontWeight='700'>
                      {cart.cartTotalPrice
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                      đ
                    </Th>
                    <Th>({cart.cartTotalQuantity})</Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Container>
    </Box>
  );
}

export default Cart;
